@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Variable.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Variable.woff") format("woff"),
    url("./assets/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SatoshiItalic";
  src: url("./assets/fonts/Satoshi-VariableItalic.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-VariableItalic.woff") format("woff"),
    url("./assets/fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.a {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

i {
  font-family: "SatoshiItalic" !important;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration,
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  display: none !important;
}
